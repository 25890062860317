import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from 'components/layout'

import '../scss/style.scss'

const HeaderLineIndex = () => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="display-4 pagetitle">Coaching</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PictureLine = data => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row my-5">
          <div class="col-12 col-md-6">
            <div class="devices pl-md-4 pt-5 pt-md-0 pr-3">
              <Img
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Coaching page image"
                className="rounded shadow-lg"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 my-5">
            <p class="lead">Dissatisfied with how your life’s progressing?</p>
            <p class="lead">Life’s problems weighing you down?</p>
            <p class="lead">Not meeting your goals?</p>
            <p class="lead">Surrounded by negative thoughts?</p>
            <p class="lead">Lacking self-confidence?</p>
            <p class="lead">
              Need to take control of your life and move forward?
            </p>
            <p class="text-right pagequote">Yes, coaching can help.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Description = () => {
  return (
    <div class="container pl-5 my-5">
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            One on one sessions focusing on personal breakthroughs, explore your
            suppressed desires, motivations and thoughts. You will learn to
            replace negative feelings and emotions with self-fulfillment.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through coaching processes, you are guided in realizing your
            strengths, helping you to become more confident and assured. You
            will learn to put your talents and skills to better use and gain
            clarity about your goals.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            You are guided on your journey to successful personal development by
            understanding yourself, valuing your talents and skills and boosting
            your self-esteem.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through introspection and deep reflection, you will be able to gain
            clarity of direction in life.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching empowers you and gives you the confidence to successfully
            meet personal and professional challenges with poise and assurance
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through coaching techniques, you are guided to confront
            psychological challenges so that you can defeat fears, anxieties and
            other negative emotions that hinders you from personal and
            professional success.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            You can be guided on finding a work-life balance through critical
            thinking skills that will make you more effective when solving
            problems, make better decisions and help you balance your emotions.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Many people don’t reach their true potential based on personal
            limitations. Coaching can help you realise your true potential and
            get rid of the obstacles that are holding you back.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ({ data, location }) => (
  <Layout location={location} crumbLabel="Coaching">
    <SEO site={get(data, 'site.meta')} />
    {HeaderLineIndex()}
    {PictureLine(data)}
    {Description()}
  </Layout>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "cherry-blossoms-4069596_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
